import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-29568ba2"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "collection"
};
var _hoisted_2 = {
  class: "mt-header"
};
import { ref } from 'vue';
import Header from '@/components/me/header.vue';
import ProductList from '@/components/other/product-list.vue';
import { collection as collectionApi } from '@/api/credits';
export default {
  setup: function setup(__props) {
    var pageData = ref([]);

    var init = function init() {
      collectionApi().then(function (res) {
        pageData.value = res;
      }).catch();
    };

    init();
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(Header, {
        title: "我的收藏",
        name: "-1"
      }), _createElementVNode("div", _hoisted_2, [_createVNode(ProductList, {
        data: pageData.value,
        remove: true
      }, null, 8, ["data"])])]);
    };
  }
};