import "core-js/modules/es.array.splice.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-19f68500"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "item-container"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = ["src", "alt"];
var _hoisted_4 = {
  class: "item-title"
};
var _hoisted_5 = {
  class: "bottom"
};
import { watch, ref } from 'vue';
import router from '@/router';
import { addFavorite as creditsAddFavoriteApi, removeFavorite as creditsRemoveFavoriteApi } from '@/api/credits';
export default {
  props: {
    data: Array,
    remove: Boolean
  },
  setup: function setup(__props) {
    var props = __props;
    var pageData = ref([]);
    watch(function () {
      return props.data;
    }, function (v) {
      pageData.value = v;
    }, {
      deep: true,
      immediate: false
    });

    var handleRemoveCollectProduct = function handleRemoveCollectProduct(i, idx) {
      creditsRemoveFavoriteApi(i.pid).then(function () {
        i.favorite = 0;

        if (props.remove) {
          pageData.value.splice(idx, 1);
        }
      }).catch();
    };

    var handleCollectProduct = function handleCollectProduct(i) {
      creditsAddFavoriteApi(i.pid).then(function () {
        i.favorite = 1;
      }).catch();
    };

    return function (_ctx, _cache) {
      var _component_coin = _resolveComponent("coin");

      var _component_el_icon = _resolveComponent("el-icon");

      var _component_star = _resolveComponent("star");

      var _component_star_filled = _resolveComponent("star-filled");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pageData.value, function (item, idx) {
        return _openBlock(), _createElementBlock("div", {
          class: "item-list",
          key: item.pid
        }, [_createElementVNode("div", {
          class: "item-content",
          onClick: function onClick($event) {
            return _unref(router).push({
              name: 'product-info',
              params: {
                id: item.pid
              }
            });
          }
        }, [_createElementVNode("img", {
          src: item.image_small,
          alt: item.title
        }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_5, [_createElementVNode("span", null, [_createVNode(_component_el_icon, null, {
          default: _withCtx(function () {
            return [_createVNode(_component_coin)];
          }),
          _: 1
        }), _createTextVNode(" " + _toDisplayString(item.price), 1)]), item.favorite === 0 ? (_openBlock(), _createBlock(_component_el_icon, {
          key: 0,
          onClick: _withModifiers(function ($event) {
            return handleCollectProduct(item);
          }, ["stop"]),
          class: "right"
        }, {
          default: _withCtx(function () {
            return [_createVNode(_component_star)];
          }),
          _: 2
        }, 1032, ["onClick"])) : (_openBlock(), _createBlock(_component_el_icon, {
          key: 1,
          onClick: _withModifiers(function ($event) {
            return handleRemoveCollectProduct(item, idx);
          }, ["stop"]),
          class: "right filled"
        }, {
          default: _withCtx(function () {
            return [_createVNode(_component_star_filled)];
          }),
          _: 2
        }, 1032, ["onClick"]))])], 8, _hoisted_2)]);
      }), 128))]);
    };
  }
};